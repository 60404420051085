import { globals } from '../globals/globals';
import { formatNumber } from './helpers';

export function calculateUSDinDeSo(p_usd: number) {
    if(p_usd === 0){
        return 0;
    }
    if(globals.exchangeRate){
        const dollarPerDeSo = globals.exchangeRate.USDCentsPerDeSoExchangeRate / 100;
        const desoPerDollar = 1 / dollarPerDeSo;
        const deso = p_usd * desoPerDollar;
        return deso;
    }
    else return 0;
}

export function calculateDeSoInUSD(p_nanos: number) {
    if (globals.exchangeRate) {
        const dollarPerDeSo = globals.exchangeRate.USDCentsPerDeSoExchangeRate / 100;
        const dollarPerNano = dollarPerDeSo / 1000000000;
        let result = dollarPerNano * p_nanos;
        result = Math.round((result + Number.EPSILON) * 100) / 100;
        return result;
    }

    return 0;
}

export function calculateAndFormatDeSoInUsd(p_nanos: number) {
    return formatNumber(calculateDeSoInUSD(p_nanos));
}

export function nanosToDeso(nanos: number, p_toFixed = 3) {
    const usdAmount = (nanos / 1000000000).toFixed(p_toFixed);
    return usdAmount;
}

export function nanosToUsd(nanos: number) {
    const usdAmount = calculateAndFormatDeSoInUsd(nanos);
    return usdAmount;
}
