const headers = {
    'content-type': 'application/json',
};

const host = 'https://node.deso.org/api/v0/';

const desofyHost = 'https://node.deso.org/api/v0/';

async function handleResponse(p_response: Response) {
    if (p_response.ok) {
        return p_response.json().catch(() => { });
    } else {
        let json = undefined;
        try {
            json = await p_response.json();
        } catch {
        }
        const error = new Error();
        (error as any).response = p_response;
        (error as any).json = json;
        (error as any).status = p_response.status;
        throw error;
    }
}

const get = (p_route: string, p_useHost = true, noCache = false) => {
    const newHeaders: any = headers;

    if (noCache) {
        newHeaders['cache-control'] = 'no-cache';
    }

    return fetch(
        p_useHost ? host + p_route : p_route,
        { headers: newHeaders }
    ).then(p_response => handleResponse(p_response));
};

const post = (p_route: string, p_body: any, p_useHost = true,) => {
    return fetch(
        p_useHost ? host + p_route : p_route,
        {
            headers: headers,
            method: 'POST',
            body: JSON.stringify(p_body)
        }
    ).then(async p_response => await handleResponse(p_response));
};

const desofyPost = (p_route: string, p_body: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        fetch(
            desofyHost + p_route,
            {
                headers: headers,
                method: 'POST',
                body: JSON.stringify(p_body)
            }
        )
            .then(p_response => handleResponse(p_response))
            .then(resolve)
            .catch(() => {
                post(p_route, p_body).then(resolve).catch(reject);
            });
    });
};


function buyCreatorCoin(p_updaterPublicKey: string, p_creatorPublicKey: string, p_bitcloutInNanos: number) {
    const route = 'buy-or-sell-creator-coin';

    return desofyPost(
        route,
        {
            UpdaterPublicKeyBase58Check: p_updaterPublicKey,
            CreatorPublicKeyBase58Check: p_creatorPublicKey,
            OperationType: 'buy',
            DeSoToSellNanos: p_bitcloutInNanos,
            CreatorCoinToSellNanos: 0,
            DeSoToAddNanos: 0,
            MinDeSoExpectedNanos: 0,
            MinCreatorCoinExpectedNanos: 0,
            MinFeeRateNanosPerKB: 1200,
        }
    );
}

function sellCreatorCoin(
    p_updaterPublicKey: string,
    p_creatorPublicKey: string,
    p_bitcloutInNanos: number
) {
    const route = "buy-or-sell-creator-coin";

    return desofyPost(route, {
        UpdaterPublicKeyBase58Check: p_updaterPublicKey,
        CreatorPublicKeyBase58Check: p_creatorPublicKey,
        OperationType: "sell",
        DeSoToSellNanos: 0,
        CreatorCoinToSellNanos: p_bitcloutInNanos,
        DeSoToAddNanos: 0,
        MinDeSoExpectedNanos: 0,
        MinCreatorCoinExpectedNanos: 0,
        MinFeeRateNanosPerKB: 1200,
    });
}

export const api = {
    buyCreatorCoin,
    sellCreatorCoin
};
