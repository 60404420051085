import React, { Component } from "react";
import DesofyLoader from "../../../components/DesofyLoader";
import { api } from "../../../service/api";
import { nanosToUsd } from "../../../service/desoCalculator";
import { themeStyles } from "../../../styles/globalColors";
import { Profile, User } from "../../../types";
import { WindowMessageType } from "../../../types/enums";

interface Props {
  profile: Profile | null;
  user: User | null;
  sendMessage: (p_type: WindowMessageType, p_message: any) => void;
}

interface State {
  amount: number;
  loadingDetails: boolean;
  errorMessage: string;
  isReviewing: boolean;
  transactionHex: string | null;
  isSelling: boolean;
  creatorCoinsHolded: number;
  expectedDesoReturnedNanos: number;
}

export default class SellCoin extends Component<Props, State> {
  constructor(p_props: Props) {
    super(p_props);
    this.state = {
      amount: 0,
      loadingDetails: false,
      errorMessage: "",
      isReviewing: false,
      transactionHex: null,
      isSelling: false,
      creatorCoinsHolded: 0,
      expectedDesoReturnedNanos: 0,
    };
  }

  componentDidMount() {
    const { user, profile } = this.props;
    if (!user || !profile) {
      return;
    }
    const holding = user.UsersYouHODL.find(
      (holding) =>
        holding.CreatorPublicKeyBase58Check === profile.PublicKeyBase58Check
    );
    if (holding) {
      this.setState({
        creatorCoinsHolded: holding.BalanceNanos,
      });
    }
  }

  updateAmount = (amount: number) => {
    this.setState({
      amount: amount,
      errorMessage: "",
    });

    let ExpectedDeSoReturnedNanos = 0;
    let TransactionHex: string | null = null;

    if (this.props.user && this.props.profile && amount > 0) {
      // TODO: can research for more explanation of the follwoing logic:
      if (amount <= 0.0000001) {
        this.setState({
          errorMessage: " Amount must be numbers and decimals only",
        });
        return;
      }
      this.setState({
        loadingDetails: true,
      });

      const amountInNanos = Math.floor(amount * 1e9);

      api
        .sellCreatorCoin(
          this.props.user.PublicKeyBase58Check,
          this.props.profile.PublicKeyBase58Check,
          amountInNanos
        )
        .then((res) => {
          if (res.ExpectedDeSoReturnedNanos) {
            ExpectedDeSoReturnedNanos = res.ExpectedDeSoReturnedNanos;
            TransactionHex = res.TransactionHex;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          let errorMessage = "";
          if (this.props.user) {
            if (amountInNanos > this.state.creatorCoinsHolded) {
              errorMessage = `Amount must be less than ${
                this.state.creatorCoinsHolded / 1e9
              } (your balance net of fees)`;
            }
          }
          this.setState({
            expectedDesoReturnedNanos: ExpectedDeSoReturnedNanos,
            loadingDetails: false,
            errorMessage,
            transactionHex: TransactionHex,
          });
        });
    } else {
      this.setState({
        expectedDesoReturnedNanos: 0,
      });
    }
  };

  handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const amount = Number(e.target.value);
    if (typeof amount === "number") {
      this.updateAmount(Number(amount));
    }
  };

  isSellingOwnCoin = () => {
    return (
      this.props.profile?.PublicKeyBase58Check ===
      this.props.user?.PublicKeyBase58Check
    );
  };

  sellCoin = () => {
    if (this.state.transactionHex) {
      this.setState({
        isSelling: true,
      });
      this.props.sendMessage(
        WindowMessageType.SELL_CREATOR_COIN_REQUEST,
        this.state.transactionHex
      );
    }
  };

  review = () => {
    this.setState({
      isReviewing: true,
    });
  };

  backFromReview = () => {
    this.setState({
      isReviewing: false,
    });
  };

  setMaxAmount = () => {
    let maxAmount = this.state.creatorCoinsHolded / 1e9;
    maxAmount = Math.max(0, maxAmount);

    maxAmount = parseFloat(maxAmount.toFixed(9));
    this.updateAmount(maxAmount);
  };

  desoPriceOfCreatorCoin = () => {
    return this.state.expectedDesoReturnedNanos / 1e9 / this.state.amount;
  };

  render() {
    const { user, profile } = this.props;
    const { amount, expectedDesoReturnedNanos } = this.state;

    if (!user || !profile) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
          }}
        >
          <DesofyLoader />
        </div>
      );
    }

    const isReviewButtonDisabled: boolean =
      !(this.state.amount > 0) ||
      this.state.errorMessage.length !== 0 ||
      this.state.loadingDetails;

    return (
      <div>
        {this.state.isReviewing ? (
          <div />
        ) : (
          <React.Fragment>
            <div
              style={{
                padding: 20,
                paddingLeft: 10,
                paddingRight: 10,
                borderBottom: "0.8px solid lightgray",
                ...themeStyles.fontColorMain,
              }}
            >
              {this.state.creatorCoinsHolded / 1e9} {profile.Username} coin
              available ≈ ${nanosToUsd(this.state.creatorCoinsHolded)}
            </div>
            <div
              style={{
                padding: 10,
                paddingLeft: 5,
                paddingRight: 5,
                borderBottom: "0.8px solid lightgray",
                ...themeStyles.fontColorMain,
              }}
            >
              <div style={{ fontSize: 12, color: "gray", marginBottom: 4 }}>
                AMOUNT
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <input
                  style={{
                    width: "85%",
                    height: 40,
                    fontSize: 18,
                    border: "1px solid gray",
                  }}
                  type="number"
                  onChange={this.handleChange}
                  value={amount}
                />
                <div
                  onClick={this.setMaxAmount}
                  style={{
                    maxWidth: "10%",
                    padding: 10,
                    ...themeStyles.linkColor,
                    ...themeStyles.containerColorMain,
                  }}
                >
                  Max
                </div>
              </div>
              <div
                style={{
                  display: this.state.errorMessage ? "block" : "none",
                  color: "red",
                }}
              >
                {this.state.errorMessage}
              </div>
            </div>
          </React.Fragment>
        )}
        {this.state.errorMessage ? (
          <div></div>
        ) : this.state.loadingDetails ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          >
            <DesofyLoader />
          </div>
        ) : (
          <div>
            {this.state.isReviewing ? (
              <div
                style={{
                  padding: 10,
                  paddingLeft: 5,
                  paddingRight: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                  ...themeStyles.fontColorMain,
                }}
              >
                Confirm Operation
              </div>
            ) : undefined}
            <div
              style={{
                padding: 10,
                paddingLeft: 5,
                paddingRight: 5,
                borderBottom: "0.8px solid lightgray",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                ...themeStyles.fontColorMain,
              }}
            >
              <div>You're exchanging</div>
              <div>
                {this.state.amount} {profile.Username} coin
              </div>
            </div>
            <div
              style={{
                padding: 10,
                paddingLeft: 5,
                paddingRight: 5,
                borderBottom: "0.8px solid lightgray",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                ...themeStyles.fontColorMain,
              }}
            >
              <div>You receive</div>
              <div>
                {expectedDesoReturnedNanos / 1e9} DeSo ≈ $
                {nanosToUsd(expectedDesoReturnedNanos)} USD
              </div>
            </div>
            {amount > 0 && (
              <div
                style={{
                  padding: 10,
                  paddingLeft: 5,
                  paddingRight: 5,
                  borderBottom: "0.8px solid lightgray",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  ...themeStyles.fontColorMain,
                }}
              >
                <div>Price per coin</div>
                <div>
                  ~{this.desoPriceOfCreatorCoin().toFixed(4)} DeSo ≈ $
                  {nanosToUsd(this.desoPriceOfCreatorCoin() * 1e9)} USD
                </div>
              </div>
            )}
          </div>
        )}
        <div style={{ height: 20 }} />
        {this.state.isReviewing ? (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <button
              style={{
                fontSize: 20,
                padding: 10,
                borderRadius: 15,
                minWidth: 130,
                color: "white",
                background: "red",
                borderColor: "red",
              }}
              onClick={this.backFromReview}
            >
              Cancel
            </button>
            <button
              style={{
                color: "white",
                ...themeStyles.accentBackgroundColor,
                fontSize: 20,
                padding: 10,
                borderRadius: 15,
                minWidth: 140,
              }}
              disabled={this.state.isSelling}
              onClick={this.sellCoin}
            >
              Sell Coins
            </button>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              style={{
                color: "white",
                ...(isReviewButtonDisabled
                  ? themeStyles.disabledButton
                  : themeStyles.accentBackgroundColor),
                fontSize: 20,
                padding: 10,
                borderRadius: 15,
                minWidth: 130,
              }}
              disabled={isReviewButtonDisabled}
              onClick={this.review}
            >
              Review
            </button>
          </div>
        )}
      </div>
    );
  }
}
