import React, { useEffect, useState } from "react";
import DesofyLoader from "../../components/DesofyLoader";
import TabsComponent from "../../components/TabsComponent";
import { globals } from "../../globals/globals";
import { nanosToUsd } from "../../service/desoCalculator";
import { themeStyles, updateThemeStyles } from "../../styles/globalColors";
import { Profile, User } from "../../types";
import { WindowMessageType } from "../../types/enums";
import BuyCoin from "./components/BuyCoin";
import SellCoin from "./components/SellCoin";
import {DesofyTheme} from '../../types/enums';

const TABS = ["Buy", "Sell"];

function CreatorCoinsPage() {
  const urlParams = new URLSearchParams(window.location.search);
  const defaultTab = urlParams.get("tab");
  const [profile, setProfile] = useState<Profile | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const [tabs, setTabs] = useState<string[]>(["Buy"]);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const sendMessage = (p_type: WindowMessageType, p_message?: any) => {
    const message = JSON.stringify({ type: p_type, message: p_message });
    if (window && (window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(message);
    }
  };

  useEffect(() => {
    if (profile && user) {
      const holding = user.UsersYouHODL.find(
        (holding) =>
          holding.CreatorPublicKeyBase58Check === profile.PublicKeyBase58Check
      );
      if (holding && holding.BalanceNanos > 0) {
        setTabs(TABS);
        if (defaultTab === TABS[1]) {
          setSelectedTabIndex(1);
        }
      }
      sendMessage(WindowMessageType.STOP_LOADER);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, user]);

  const onMessage = (event: any) => {
    const data = JSON.parse(event.data);

    switch (data.type) {
      case WindowMessageType.GET_PROFILE_RESPONSE:
        setProfile(data.message);
        break;
      case WindowMessageType.GET_USER_RESPONSE:
        setUser(data.message);
        break;
      case WindowMessageType.SET_THEME:
        const documentElement = window.document.documentElement;
        const isDark = data.message.darkMode;
        const theme = data.message.theme;

        documentElement.classList.remove(DesofyTheme.Dark);
        documentElement.classList.remove(DesofyTheme.Light);
        documentElement.classList.remove(DesofyTheme.navy);
        documentElement.classList.add(theme);
        globals.isDarkMode = isDark;
        globals.theme = theme;
        updateThemeStyles();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if(/Android/.test(userAgent)) {
      document.addEventListener("message", onMessage);
    }else {
      window.addEventListener("message", onMessage);
    }
    sendMessage(WindowMessageType.GET_PROFILE_REQUEST);
    sendMessage(WindowMessageType.GET_USER_REQUEST);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!profile || !user) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 200,
        }}
      >
        <DesofyLoader />
      </div>
    );
  }

  const renderContent = () => {
    switch (selectedTabIndex) {
      case 0:
        return (
          <BuyCoin profile={profile} user={user} sendMessage={sendMessage} />
        );
      case 1:
        return (
          <SellCoin profile={profile} user={user} sendMessage={sendMessage} />
        );
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        maxWidth: 450,
        paddingLeft: 5,
        paddingRight: 5,
        ...themeStyles.containerColorMain,
      }}
    >
      <div>
        <div
          style={{
            paddingTop: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt={profile.Username}
            src={`https://node.deso.org/api/v0/get-single-profile-picture/${profile.PublicKeyBase58Check}`}
            style={{ width: 112, height: 112, borderRadius: 75 }}
          />
          <div style={{ marginTop: 5, ...themeStyles.fontColorMain }}>
            {profile?.Username}'s Coin
          </div>
          <div
            style={{
              marginTop: 5,
              marginBottom: 5,
              ...themeStyles.fontColorMain,
            }}
          >
            ≈${nanosToUsd(profile.CoinPriceBitCloutNanos)}
            <span style={{ ...themeStyles.fontColorSub }}> Coin Price</span>
          </div>
        </div>
        <TabsComponent
          tabs={tabs}
          selectedTabIndex={selectedTabIndex}
          onChangeTabIndex={setSelectedTabIndex}
        />
        {renderContent()}
      </div>
    </div>
  );
}

export default CreatorCoinsPage;
