import React, { Component } from "react";
import styled from "styled-components";
import DesofyLoader from "../../../components/DesofyLoader";
import { api } from "../../../service/api";
import {
  calculateUSDinDeSo,
  nanosToDeso,
  nanosToUsd,
} from "../../../service/desoCalculator";
import { themeStyles } from "../../../styles/globalColors";
import { Profile, User } from "../../../types";
import { WindowMessageType } from "../../../types/enums";

enum Currency {
  USD = "USD",
  DESO = "DESO",
}

interface Props {
  profile: Profile | null;
  user: User | null;
  sendMessage: (p_type: WindowMessageType, p_message: any) => void;
}

interface State {
  amount: number;
  currency: Currency;
  expectedCreatorCoinsNanos: number;
  loadingDetails: boolean;
  errorMessage: string;
  isReviewing: boolean;
  transactionHex: string | null;
  isBuying: boolean;
  expectedFounderRewardNanos: number;
}

const SeelectWrapper = styled.div`
  display: inline-block;
  select::-ms-expand {
    display: none;
  }

  select {
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    background: none;
    border: 0.5px solid;
    outline: none;
    padding: 12.5px;
  }
`;

export default class BuyCoin extends Component<Props, State> {
  constructor(p_props: Props) {
    super(p_props);
    this.state = {
      amount: 0,
      currency: Currency.USD,
      expectedCreatorCoinsNanos: 0,
      loadingDetails: false,
      errorMessage: "",
      isReviewing: false,
      transactionHex: null,
      isBuying: false,
      expectedFounderRewardNanos: 0,
    };
  }

  updateAmount = (amount: number, currency: Currency = this.state.currency) => {
    this.setState({
      amount: amount,
      currency: currency,
      errorMessage: "",
    });

    let ExpectedCreatorCoinReturnedNanos = 0;
    let FounderRewardGeneratedNanos = 0;
    let FeeNanos = 0;
    let TransactionHex: string | null = null;

    if (this.props.user && this.props.profile && amount > 0) {
      // TODO: can research for more explanation of the follwoing logic:
      if (amount <= 0.0000001) {
        this.setState({
          errorMessage: " Amount must be numbers and decimals only",
        });
        return;
      }
      this.setState({
        loadingDetails: true,
      });

      const amountInNanos =
        currency === Currency.USD
          ? Math.round(calculateUSDinDeSo(amount) * 1e9)
          : amount * 1e9;

      api
        .buyCreatorCoin(
          this.props.user.PublicKeyBase58Check,
          this.props.profile.PublicKeyBase58Check,
          amountInNanos
        )
        .then((res) => {
          if (res.ExpectedCreatorCoinReturnedNanos) {
            ExpectedCreatorCoinReturnedNanos =
              res.ExpectedCreatorCoinReturnedNanos;
            FounderRewardGeneratedNanos = res.FounderRewardGeneratedNanos;
            FeeNanos = res.FeeNanos;
            TransactionHex = res.TransactionHex;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          let errorMessage = "";
          if (this.props.user) {
            let maxBalance = "";
            if (this.state.currency === Currency.DESO) {
              maxBalance =
                nanosToDeso(this.props.user.BalanceNanos - FeeNanos, 9) +
                " DeSo";
            } else if (this.state.currency === Currency.USD) {
              maxBalance =
                "$" +
                nanosToUsd(this.props.user.BalanceNanos - FeeNanos) +
                " USD";
            }
            if (FeeNanos + amountInNanos > this.props.user.BalanceNanos) {
              errorMessage = `Amount must be less than ${maxBalance} (your balance net of fees)`;
            }
          }
          this.setState({
            expectedCreatorCoinsNanos: ExpectedCreatorCoinReturnedNanos,
            expectedFounderRewardNanos: FounderRewardGeneratedNanos,
            loadingDetails: false,
            errorMessage,
            transactionHex: TransactionHex,
          });
        });
    } else {
      this.setState({
        expectedCreatorCoinsNanos: 0,
      });
    }
  };

  handleChangeAmount: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const amount = Number(e.target.value);
    if (typeof amount === "number") {
      this.updateAmount(Number(amount));
    }
  };

  handleChangeCurrency: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const currency = e.target.value as Currency;
    this.updateAmount(this.state.amount, currency);
  }

  isBuyingOwnCoin = () => {
    return (
      this.props.profile?.PublicKeyBase58Check ===
      this.props.user?.PublicKeyBase58Check
    );
  };

  buyCoin = () => {
    if (this.state.transactionHex) {
      this.setState({
        isBuying: true,
      });
      this.props.sendMessage(
        WindowMessageType.BUY_CREATOR_COIN_REQUEST,
        this.state.transactionHex
      );
    }
  };

  review = () => {
    this.setState({
      isReviewing: true,
    });
  };

  backFromReview = () => {
    this.setState({
      isReviewing: false,
    });
  };

  desoPriceOfCreatorCoin = () => {
    let desoAmount = this.state.amount;
    if (desoAmount > 0) {
      if (this.state.currency === Currency.USD) {
        desoAmount = calculateUSDinDeSo(this.state.amount);
      }
      if (this.isBuyingOwnCoin()) {
        return (
          desoAmount /
          ((this.state.expectedCreatorCoinsNanos +
            this.state.expectedFounderRewardNanos) /
            1e9)
        );
      }
      return desoAmount / (this.state.expectedCreatorCoinsNanos / 1e9);
    } else {
      return 0;
    }
  };

  assetReturnedAmount = () => {
    if (this.isBuyingOwnCoin()) {
      return (
        (this.state.expectedCreatorCoinsNanos +
          this.state.expectedFounderRewardNanos) /
        1e9
      );
    }
    return this.state.expectedCreatorCoinsNanos / 1e9;
  };

  getExchangingAmountInDeso = () => {
    let desoAmount = this.state.amount;
    if (this.state.currency === Currency.USD) {
      desoAmount = calculateUSDinDeSo(this.state.amount);
    }
    return Number(desoAmount);
  };

  render() {
    const { user, profile } = this.props;
    const { amount } = this.state;

    if (!user || !profile) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
          }}
        >
          <DesofyLoader />
        </div>
      );
    }

    if (profile.CoinEntry.CreatorBasisPoints === 10000) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
            padding: 10
          }}
        >
          <p>This creator has set their founder's reward to 100%.<br /> You cannot buy creators that have set their founder's reward to 100%.</p>
        </div>
      );
    }

    const isReviewButtonDisabled: boolean =
      !(this.state.amount > 0) ||
      this.state.errorMessage.length !== 0 ||
      this.state.loadingDetails;

    return (
      <div>
        {this.state.isReviewing ? (
          <div />
        ) : (
          <React.Fragment>
            <div
              style={{
                padding: 20,
                paddingLeft: 10,
                paddingRight: 10,
                borderBottom: "0.8px solid lightgray",
                ...themeStyles.borderColor,
                ...themeStyles.fontColorMain,
              }}
            >
              {user.BalanceNanos / 1e9} DeSo available ≈ $
              {nanosToUsd(user.BalanceNanos)}
            </div>
            <div
              style={{
                padding: 10,
                paddingLeft: 5,
                paddingRight: 5,
                borderBottom: "0.8px solid lightgray",
                ...themeStyles.borderColor,
              }}
            >
              <div
                style={{
                  fontSize: 12,
                  ...themeStyles.fontColorSub,
                  marginBottom: 4,
                }}
              >
                AMOUNT
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <SeelectWrapper>
                  <select
                    style={{ ...themeStyles.fontColorMain }}
                    value={this.state.currency}
                    onChange={this.handleChangeCurrency}
                  >
                    <option value={Currency.USD}>USD</option>
                    <option value={Currency.DESO}>DeSo</option>
                  </select>
                </SeelectWrapper>
                <input
                  style={{
                    width: "75%",
                    height: 40,
                    fontSize: 18,
                    border: "1px solid gray",
                  }}
                  type="number"
                  onChange={this.handleChangeAmount}
                  value={amount}
                />
              </div>
              <div
                style={{
                  display: this.state.errorMessage ? "block" : "none",
                  color: "red",
                }}
              >
                {this.state.errorMessage}
              </div>
            </div>
          </React.Fragment>
        )}
        {this.state.errorMessage ? (
          <div></div>
        ) : this.state.loadingDetails ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          >
            <DesofyLoader />
          </div>
        ) : (
          <div>
            {this.state.isReviewing ? (
              <div
                style={{
                  padding: 10,
                  paddingLeft: 5,
                  paddingRight: 5,
                  fontWeight: "bold",
                  fontSize: 18,
                  ...themeStyles.fontColorMain,
                }}
              >
                Confirm Operation
              </div>
            ) : undefined}
            <div
              style={{
                padding: 10,
                paddingLeft: 5,
                paddingRight: 5,
                borderBottom: "0.8px solid lightgray",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                ...themeStyles.fontColorMain,
              }}
            >
              <div>You're exchanging</div>
              <div>
                {this.getExchangingAmountInDeso().toFixed(9)} DeSo ≈ $
                {nanosToUsd(this.getExchangingAmountInDeso() * 1e9)} USD
              </div>
            </div>
            <div
              style={{
                padding: 10,
                paddingLeft: 5,
                paddingRight: 5,
                borderBottom: "0.8px solid lightgray",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                ...themeStyles.fontColorMain,
              }}
            >
              <div>You receive</div>
              <div>
                {this.assetReturnedAmount()} {profile.Username} coin
              </div>
            </div>
            {amount > 0 && (
              <div
                style={{
                  padding: 10,
                  paddingLeft: 5,
                  paddingRight: 5,
                  borderBottom: "0.8px solid lightgray",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  ...themeStyles.fontColorMain,
                }}
              >
                <div>Price per coin</div>
                <div>
                  ~{this.desoPriceOfCreatorCoin().toFixed(4)} DeSo ≈ $
                  {nanosToUsd(this.desoPriceOfCreatorCoin() * 1e9)} USD
                </div>
              </div>
            )}
          </div>
        )}
        <div
          style={{
            padding: 10,
            borderBottom: "0.8px solid lightgray",
            ...themeStyles.fontColorMain,
          }}
        >
          @{profile.Username} will receive{" "}
          {profile.CoinEntry.CreatorBasisPoints / 100}% of your purchase as a
          founder reward.
        </div>
        <div style={{ height: 20 }} />
        {this.state.isReviewing ? (
          <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <button
              style={{
                fontSize: 20,
                padding: 10,
                borderRadius: 15,
                minWidth: 130,
                color: "white",
                background: "red",
                borderColor: "red",
              }}
              onClick={this.backFromReview}
            >
              Cancel
            </button>
            <button
              style={{
                color: "white",
                ...themeStyles.accentBackgroundColor,
                fontSize: 20,
                padding: 10,
                borderRadius: 15,
                minWidth: 140,
              }}
              disabled={this.state.isBuying}
              onClick={this.buyCoin}
            >
              Buy Coins
            </button>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <button
              style={{
                color: "white",
                ...(isReviewButtonDisabled
                  ? themeStyles.disabledButton
                  : themeStyles.accentBackgroundColor),
                fontSize: 20,
                padding: 10,
                borderRadius: 15,
                minWidth: 130,
              }}
              disabled={isReviewButtonDisabled}
              onClick={this.review}
            >
              Review
            </button>
          </div>
        )}
      </div>
    );
  }
}
