const SYMBOLs = ['', 'k', 'M', 'G', 'T', 'P', 'E'];

export function formatNumber(p_number: number, p_includeDecimalPlaces = true, p_decimalPlaces = 2) {

    const tier = Math.log10(Math.abs(p_number)) / 3 | 0;

    if (tier <= 0) {
        return p_includeDecimalPlaces ? p_number.toFixed(p_decimalPlaces) : p_number.toString();
    }

    const suffix = SYMBOLs[tier];
    const scale = Math.pow(10, tier * 3);

    const scaled = p_number / scale;

    return scaled.toFixed(1) + suffix;
}