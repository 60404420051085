import { Component, FC } from "react";

export interface RouteConfig {
  id?: string;
  path: string;
  name: string;
  component: typeof Component | FC<any>;
  guard?: typeof Component | FC;
  children?: Array<RouteConfig>;
  props?: any;
  messageLayout?: boolean;
}

export interface CustomSelectOption {
  text: string;
  value: string;
}

export interface Theme {
  name: string;
  background: string;
  primaryColor: string;
  secondaryColor: string;
  accentColor: string;
  tertiaryColor: string;
  secondaryBackground: string;
}

export interface User {
  publicKey: string;
}

export interface CoinEntry {
  BitCloutLockedNanos: number;
  CoinWatermarkNanos: number;
  NumberOfHolders: number;
  CoinsInCirculationNanos: number;
  CreatorBasisPoints: number;
}

export interface User {
  BalanceNanos: number;
  BlockedPubKeys: any;
  CanCreateProfile: boolean;
  HasPhoneNumber: boolean;
  IsAdmin: boolean;
  ProfileEntryResponse: Profile;
  PublicKeyBase58Check: string;
  PublicKeysBase58CheckFollowedByUser: string[];
  UsersWhoHODLYou: CreatorCoinHODLer[];
  UsersYouHODL: CreatorCoinHODLer[];
}

export interface Profile {
  ProfilePic: string;
  Username: string;
  Description: string;
  PublicKeyBase58Check: string;
  CoinPriceBitCloutNanos: number;
  CoinEntry: CoinEntry;
  IsVerified: boolean;
  CoinPriceUSD?: number;
  FormattedCoinPriceUSD?: string;
  Posts: Post[];
  UsersThatHODL?: CreatorCoinHODLer[];
}

export interface PostReaderState {
  LikedByReader: boolean;
  RecloutedByReader: boolean;
  RecloutPostHashHex?: string;
  DiamondLevelBestowed: number;
}

export interface PostExtraData {
  EmbedVideoURL: string;
}

export interface Post {
  PostHashHex: string;
  Body: string;
  ImageURLs: string[];
  TimestampNanos: number;
  ProfileEntryResponse: Profile;
  LikeCount: number;
  PostEntryReaderState: PostReaderState | null;
  CommentCount: number;
  Comments: Post[];
  RecloutCount: number;
  RecloutedPostEntryResponse: Post;
  DiamondCount: number;
  ParentStakeID: string;
  PostExtraData: PostExtraData;
  ConfirmationBlockHeight: number;
  CreatorBasisPoints: number;
  InGlobalFeed: boolean;
  InMempool: boolean;
  IsHidden: boolean;
  IsPinned: boolean;
  ParentPosts: Array<Post>;
  PosterPublicKeyBase58Check: string;
  StakeMultipleBasisPoints: number;
  QuoteRecloutCount: number;
  IsNFT: boolean;
  NumNFTCopies: number;
  NumNFTCopiesForSale: number;
  HasUnlockable: boolean;
  NFTRoyaltyToCreatorBasisPoints: number;
  NFTRoyaltyToCoinBasisPoints: number;
  DiamondsFromSender: number;
  NFTs?: Array<NFT>;
  NFTBids?: Array<Bid>;
}

export interface NFT {
  OwnerPublicKeyBase58Check: string;
  ProfileEntryResponse: Profile; //not available in getNFTsForProfile response
  SerialNumber: number;
  IsForSale: boolean;
  MinBidAmountNanos: number;
  LastAcceptedBidAmountNanos: number;
  HighestBidAmountNanos: number;
  LowestBidAmountNanos: number;
}

export interface Bid {
  PublicKeyBase58Check: string;
  ProfileEntryResponse: Profile;
  SerialNumber: number;
  BidAmountNanos: number;
  BidderBalanceNanos: number;
}

export enum NotificationType {
  SubmitPost = "SUBMIT_POST",
  BasicTransfer = "BASIC_TRANSFER",
  CreatorCoin = "CREATOR_COIN",
  Follow = "FOLLOW",
  Like = "LIKE",
  CreatorCoinTransfer = "CREATOR_COIN_TRANSFER",
  NftBid = "NFT_BID",
  AcceptNftBid = "ACCEPT_NFT_BID",
}

export interface NotificationLikeMetaData {
  IsUnlike: boolean;
  PostHashHex: string;
}

export interface NotificationFollowMetaData {
  IsUnfollow: boolean;
}

export interface NotificationSubmitPostMetaData {
  ParentPostHashHex: string;
  PostHashBeingModifiedHex: string;
}

export interface NotificationCreatorCoinMetaData {
  BitCloutToAddNanos: number;
  BitCloutToSellNanos: number;
  CreatorCoinToSellNanos: number;
  OperationType: string;
}

export interface NotificationCreatorCoinTransferMetaData {
  CreatorCoinToTransferNanos: number;
  CreatorUsername: string;
  DiamondLevel: number;
  PostHashHex: string;
}

export interface NotificationTransactionOutputResponse {
  AmountNanos: number;
  PublicKeyBase58Check: string;
}

export interface NotificationNftBidMetaData {
  BidAmountNanos: number;
  NFTPostHashHex: string;
  SerialNumber: number;
}

export interface NotificationBasicTransferMetaData {
  DiamondLevel: number;
  PostHashHex: string;
}

export interface NotificationMetaData {
  TxnType: NotificationType;
  TransactorPublicKeyBase58Check: string;
  CreatorCoinTxindexMetadata?: NotificationCreatorCoinMetaData;
  SubmitPostTxindexMetadata?: NotificationSubmitPostMetaData;
  FollowTxindexMetadata?: NotificationFollowMetaData;
  LikeTxindexMetadata?: NotificationLikeMetaData;
  CreatorCoinTransferTxindexMetadata?: NotificationCreatorCoinTransferMetaData;
  NFTBidTxindexMetadata?: NotificationNftBidMetaData;
  AcceptNFTBidTxindexMetadata?: NotificationNftBidMetaData;
  BasicTransferTxindexMetadata?: NotificationBasicTransferMetaData;
}

export interface Notification {
  Index: number;
  Metadata: NotificationMetaData;
  TxnOutputResponses: NotificationTransactionOutputResponse[];
}

export interface CreatorCoinHODLer {
  BalanceNanos: number;
  CreatorPublicKeyBase58Check: string;
  HODLerPublicKeyBase58Check: string;
  ProfileEntryResponse: Profile;
  HasPurchased: boolean;
}

export interface ContactWithMessages {
  Messages: Message[];
  NumMessagesRead: number;
  ProfileEntryResponse: Profile;
  PublicKeyBase58Check: string;
  CreatorCoinHoldingAmount?: number;
  UnreadMessages?: boolean;
}

export interface Message {
  DecryptedText?: string;
  EncryptedText: string;
  IsSender: boolean;
  RecipientPublicKeyBase58Check: string;
  SenderPublicKeyBase58Check: string;
  TstampNanos: number;
  LastOfGroup?: boolean;
  V2: boolean;
}

export interface DiamondSender {
  HighestDiamondLevel: number;
  ProfileEntryResponse: Profile;
  ReceiverPublicKeyBase58Check: string;
  SenderPublicKeyBase58Check: string;
  TotalDiamonds: number;
  DiamondLevelMap: { [key: string]: number };
}

export interface CreatorCoinTransaction {
  transactorPublicKey: string;
  coinsInCirculation: number;
  coinsChange: number;
  coinPrice: number;
  bitcloutValue: number;
  usdValue: number;
  timeStamp: number;
}

export interface CloutTag {
  clouttag: string;
  count: number;
}

export interface ProfileNFTsMap {
  [key: string]: {
    PostEntryResponse: Post;
    NFTEntryResponses: Array<NFT>;
  };
}

export interface IdentityUser {
  accessLevel: number;
  accessLevelHmac: string;
  btcDepositAddress: string;
  encryptedSeedHex: string;
  hasExtraText: boolean;
  jwt: string;
  network: string;
  publicKey: string;
}

export enum LocalStorageKeys {
  IDENTITY_USERS = "cloutfeed_identity_users",
  ACTIVE_PUBLIC_KEY = "cloutfeed_activePublicKey",
  THEME = "cloutfeed_theme",
  MESSAGE_FILTER = "message_filter_option",
  MESSAGE_SORT = "message_sort",
  SAVED_POSTS = "saved_posts",
}

export interface Bitclout24hChange {
  net_change_24h_bitclout_nanos: string;
  public_key: string;
  updated_at: string;
}

export interface BitcloutProject {
  bitclout_public_key: string;
  website_url: string;
}
