export enum WindowMessageType {
  GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST",
  GET_PROFILE_RESPONSE = "GET_PROFILE_RESPONSE",
  GET_PROFILE_ERROR = "GET_PROFILE_ERROR",
  GET_USER_REQUEST = "GET_USER_REQUEST",
  GET_USER_RESPONSE = "GET_USER_RESPONSE",
  GET_USER_ERROR = "GET_USER_ERROR",
  BUY_CREATOR_COIN_REQUEST = "BUY_CREATOR_COIN_REQUEST",
  SELL_CREATOR_COIN_REQUEST = 'SELL_CREATOR_COIN_REQUEST',
  START_LOADER = 'START_LOADER',
  STOP_LOADER = 'STOP_LOADER',
  SET_THEME = 'SET_THEME'
}

export enum DesofyTheme {
  Light = 'light',
  Dark = 'dark',
  navy = 'navy'
}