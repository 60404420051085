import React from "react";
import styled, { keyframes } from "styled-components";

const growAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
    border-radius: 0px;
  }
  100% {
    transform: scale(1);
  }
`;

const Container = styled.div`
  .animatedLogo {
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1.6s;
    -moz-transition-duration: 1.6s;
    -webkit-animation-name: ${growAnimation};
    -webkit-animation-duration: 1.6s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: ${growAnimation};
    -moz-animation-duration: 1.6s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
  }
`;

export default function DesofyLoader() {
  return (
    <Container>
      <svg
        className="animatedLogo"
        width={33}
        height={44}
        fill='none'
      >
        <g clipPath='url(#a)'>
          <path
            d='M30.864 20.168c0 4.917-1.27 9.004-3.77 12.183-.006-.031-6.803 4.836-6.809 4.8-.296-1.881-.225-3.817-.3-5.713-.097-2.454 5.474-6.396 4.845-8.785-.759-2.877-3.357-4.987-6.106-6.17a21.692 21.692 0 0 0-2.637-.92c-1.487-.423-3.007-.734-4.51-1.096-1.384-.332-2.82-.627-4.145-1.135C2.356 11.388.4 8.114.121 3.07.114 2.953.094 1.682.108 1.682h13.58c5.887 0 10.305 1.761 13.25 5.282 2.617 3.107 3.926 7.508 3.926 13.204Z'
            fill='url(#b)'
          />
          <path
            d='M30.864 20.168c0 4.917-1.27 9.004-3.77 12.183-.006-.031-6.803 4.836-6.809 4.8-.296-1.881-.225-3.817-.3-5.713-.097-2.454-.294-4.926-.923-7.314-.386-1.463 3.316-2.753 4.133-3.801.668-.858.895-2.335 1.499-3.236.413-.618 1.25-.006 1.227-.777-.018-.554-1.974-2.51-2.376-2.869-2.084-1.858-4.837-2.907-7.62-3.526-2.782-.62-5.644-.847-8.463-1.295-1.27-.201-2.543-.451-3.715-.957C2.28 7.03 1.05 5.962.45 4.523a4.407 4.407 0 0 1-.33-1.454C.115 2.954-.013-.006 0 0c.273.13 1.045.335 1.193.377.64.18 1.285.343 1.933.487 3.46.774 6.864.816 10.397.807 2.75-.008 5.304.341 7.863 1.313 2.103.832 3.976 2.15 5.418 3.825l.133.155c2.617 3.107 3.926 7.508 3.926 13.204Z'
            fill='url(#c)'
          />
          <path
            opacity={0.15}
            d='M21.826 18.324s.77.633.77.702c0 .042 5.93-8.633 4.466-9.942.073-.028-2.54 6.526-5.237 9.239Z'
            fill='#333'
          />
          <path
            d='M13.814 38.188c5.888 0 10.305-1.76 13.25-5.282 2.618-3.107 3.926-7.508 3.926-13.203 0-3.289-.589-6.642-2.08-9.63a16.56 16.56 0 0 0-.962-1.68c-.216-.33-.537-.868-.826-1.143 0 0 .053.314.068.505.045.575-.115 1.268-.263 1.83-.842 3.199-1.36 4.733-2.788 6.986-1.534 2.422-3.343 5.054-5.969 6.547a17.56 17.56 0 0 1-4.082 1.705c-1.888.51-3.87.689-5.728 1.291-2.818.914-5.413 2.843-6.787 5.378-.602 1.111-.968 2.325-1.15 3.56-.037.254-.247 3.139-.187 3.136'
            fill='url(#d)'
          />
          <path
            d='M13.826 38.206c2.215-.084 4.42-.263 6.516-.911a15.054 15.054 0 0 0 3.512-1.586 13.477 13.477 0 0 0 3.22-2.803C29.692 29.8 31 25.398 31 19.703c0-3.288-.589-6.642-2.08-9.63-.287-.576-.54-1.111-.894-1.654-.215-.33-.547-.765-.788-1.081-.04-.053-.107-.161-.176-.167 0 0 .281 1.001.358 1.373.663 3.2.3 6.731-.566 9.904-1.211 4.439-4.738 7.89-8.776 10.223-3.044 1.759-6.409 2.437-9.902 2.884-1.639.21-3.3.392-4.874.895-1.341.428-2.65 1.124-2.865 2.58C.4 35.285-.027 40 .135 40c.137 0 1.437-.435 2.003-.593.784-.218 1.581-.39 2.384-.53 1.638-.285 3.3-.43 4.962-.523 1.431-.08 2.887-.094 4.34-.148h.002Z'
            fill='url(#e)'
          />
        </g>
        <defs>
          <linearGradient
            id='b'
            x1={3.693}
            y1={18.49}
            x2={22.472}
            y2={20.559}
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#01C1FE' />
            <stop offset={0.884} stopColor='#0197FE' />
          </linearGradient>
          <linearGradient
            id='c'
            x1={0}
            y1={18.576}
            x2={30.864}
            y2={18.576}
            gradientUnits='userSpaceOnUse'
          >
            <stop offset={0.341} stopColor='#01C1FE' />
            <stop offset={0.703} stopColor='#01BCFE' />
            <stop offset={0.826} stopColor='#01AFFE' />
            <stop offset={0.888} stopColor='#0198FE' />
            <stop offset={0.939} stopColor='#0194FE' />
          </linearGradient>
          <linearGradient
            id='d'
            x1={10.076}
            y1={29.251}
            x2={30.778}
            y2={14.863}
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#355DFF' />
            <stop offset={0.821} stopColor='#068FFE' />
            <stop offset={1} stopColor='#0194FE' />
          </linearGradient>
          <linearGradient
            id='e'
            x1={11.223}
            y1={38.123}
            x2={26.017}
            y2={11.015}
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#3555FF' />
            <stop offset={0.24} stopColor='#3159FE' />
            <stop offset={0.5} stopColor='#2666FE' />
            <stop offset={0.578} stopColor='#206EFE' />
            <stop offset={0.691} stopColor='#1A74FE' />
            <stop offset={0.765} stopColor='#137DFE' />
            <stop offset={0.848} stopColor='#0B88FE' />
            <stop offset={0.897} stopColor='#058FFE' />
            <stop offset={0.968} stopColor='#0194FE' />
          </linearGradient>
          <clipPath id='a'>
            <path fill='#fff' d='M0 0h31v40H0z' />
          </clipPath>
        </defs>
      </svg>
    </Container>
  );
}
