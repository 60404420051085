import { globals } from "../globals/globals";

interface ThemeObject {
  accentColor: any;
  accentBackgroundColor: any;
  fontColorMain: any;
  switchColor: any;
  fontColorSub: any;
  containerColorMain: any;
  containerColorSub: any;
  chipColor: any;
  coinPriceContainer: any;
  borderColor: any;
  buttonBorderColor: any;
  shadowColor: any;
  recloutBorderColor: any;
  buttonDisabledColor: any;
  diamondColor: any;
  linkColor: any;
  modalBackgroundColor: any;
  disabledButton: any;
  redColor: any;
  currencyButtonBackgroundColor: any;
  peekOptionsContainer: any;
  peekOptionsBorder: any;
  navigationBorderColor: any;
  recipientMessageColor: any;
  selectedTabColor: any;
}

const themeMapper: { [key: string]: ThemeObject } = {
  light: {
      accentColor: { color: '#028deb' },
      accentBackgroundColor: { backgroundColor: '#028deb' },
      fontColorMain: { color: '#141619' },
      switchColor: { color: 'rgba(128, 128, 128, 0.4)' },
      fontColorSub: { color: '#828282' },
      containerColorMain: { backgroundColor: '#fff' },
      containerColorSub: { backgroundColor: '#f7f7f7' },
      chipColor: { backgroundColor: '#f5f5f5' },
      coinPriceContainer: { backgroundColor: '#ebebeb' },
      borderColor: { borderColor: '#ebeced' },
      buttonBorderColor: { borderColor: '#bfbfbf' },
      shadowColor: { shadowColor: '#d6d6d6' },
      recloutBorderColor: { borderColor: '#cccccc' },
      buttonDisabledColor: { backgroundColor: '#cfcfcf' },
      diamondColor: { color: '#3599d4' },
      linkColor: { color: '#047ac9' },
      modalBackgroundColor: { backgroundColor: '#f7f7f7' },
      disabledButton: { backgroundColor: '#2b2b2b' },
      redColor: { backgroundColor: '#e32727' },
      currencyButtonBackgroundColor: { backgroundColor: '#e1e1e1' },
      peekOptionsContainer: { backgroundColor: '#F0F0F0' },
      peekOptionsBorder: { borderColor: '#cccccc' },
      navigationBorderColor: { borderColor: '#f2f2f2' },
      recipientMessageColor: { backgroundColor: '#dedede' },
      selectedTabColor: { backgroundColor: '#f7f7f7' },
  },
  dark: {
      accentColor: { color: '#1994e6' },
      accentBackgroundColor: { backgroundColor: '#1994e6' },
      fontColorMain: { color: '#ebebeb' },
      switchColor: { color: 'rgba(128, 128, 128, 1)' },
      fontColorSub: { color: '#b0b3b8' },
      containerColorMain: { backgroundColor: '#000000' },
      containerColorSub: { backgroundColor: '#121212' },
      chipColor: { backgroundColor: '#262525' },
      coinPriceContainer: { backgroundColor: '#171717' },
      borderColor: { borderColor: '#1f1f1f' },
      buttonBorderColor: { borderColor: '#262626' },
      shadowColor: { shadowColor: 'black' },
      recloutBorderColor: { borderColor: '#363636' },
      buttonDisabledColor: { backgroundColor: '#2e2e2e' },
      diamondColor: { color: '#b9f2ff' },
      linkColor: { color: '#60b6f0' },
      modalBackgroundColor: { backgroundColor: '#242424' },
      disabledButton: { backgroundColor: '#2b2b2b' },
      redColor: { backgroundColor: '#e32727' },
      currencyButtonBackgroundColor: { backgroundColor: '#626262' },
      peekOptionsContainer: { backgroundColor: '#323232' },
      peekOptionsBorder: { borderColor: '#4c4c4c' },
      navigationBorderColor: { borderColor: '#141414' },
      recipientMessageColor: { backgroundColor: '#292929' },
      selectedTabColor: { backgroundColor: '#242424' },
  },
  navy: {
      accentColor: { color: '#1994e6' },
      accentBackgroundColor: { backgroundColor: '#1994e6' },
      fontColorMain: { color: '#ebebeb' },
      switchColor: { color: 'rgba(128, 128, 128, 1)' },
      fontColorSub: { color: '#b0b3b8' },
      containerColorMain: { backgroundColor: '#111a24' },
      containerColorSub: { backgroundColor: '#192633' },
      chipColor: { backgroundColor: '#1b2938' },
      coinPriceContainer: { backgroundColor: '#203042' },
      borderColor: { borderColor: '#1a2533' },
      buttonBorderColor: { borderColor: '#202f40' },
      shadowColor: { shadowColor: '#223445' },
      recloutBorderColor: { borderColor: '#233345' },
      buttonDisabledColor: { backgroundColor: '#32475c' },
      diamondColor: { color: '#b9f2ff' },
      linkColor: { color: '#60b6f0' },
      modalBackgroundColor: { backgroundColor: '#17222e' },
      disabledButton: { backgroundColor: '#2b2b2b' },
      redColor: { backgroundColor: '#e32727' },
      currencyButtonBackgroundColor: { backgroundColor: '#2f4359' },
      peekOptionsContainer: { backgroundColor: '#243342' },
      peekOptionsBorder: { borderColor: '#202f40' },
      navigationBorderColor: { borderColor: '#1b2836' },
      recipientMessageColor: { backgroundColor: '#202f40' },
      selectedTabColor: { backgroundColor: '#243342' },
  }
};

export let themeStyles = themeMapper[globals.theme]

export function updateThemeStyles() {
  themeStyles = themeMapper[globals.theme]
}
