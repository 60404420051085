import React, { useEffect } from "react";
import "./App.css";
import { globals } from "./globals/globals";
import CreatorCoinsPage from "./pages/CreatorCoinTransfer";

function App() {
  const getExchangeRate = () => {
    fetch("https://node.deso.org/api/v0/get-exchange-rate")
      .then((res) => res.json())
      .then((res) => {
        console.log("res: ", res);
        globals.exchangeRate = res;
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };

  useEffect(() => {
    getExchangeRate();
  }, []);

  return <CreatorCoinsPage />;
}

export default App;
